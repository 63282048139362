import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SessionContext } from "../../store/SessionContext";

const Breadcrumb = () => {
  const { session, setSession } = useContext(SessionContext);

  useEffect(() => {}, [session]);

  function updateStack(directory) {
    let elementIndex = 0;
    for (const [index, value] of session.directory_stack.entries()) {
      if (
        value.nav_path === directory.nav_path &&
        value.human_readable_text === directory.human_readable_text
      ) {
        elementIndex = index;
        break;
      }
    }

    setSession((state) => ({
      ...state,
      directory_stack: session.directory_stack.slice(0, elementIndex + 1),
    }));
  }

  return (
    <nav
      className="breadcrumb has-arrow-separator ml-2 mt-2 mb-2"
      aria-label="breadcrumbs"
    >
      <ul>
        {session.directory_stack.map((directory, index, dirStack) => {
          const cssClasses = [];
          if (index + 1 === dirStack.length) {
            cssClasses.push("is-active");
          }

          return (
            <li className={cssClasses.join(" ")} key={index}>
              <NavLink
                to={directory.nav_path}
                onClick={() => updateStack(directory)}
              >
                {directory.human_readable_text}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
