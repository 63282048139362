import config from "../config";
import { Auth } from 'aws-amplify';

const HTTP_GET = 'GET';
const HTTP_POST = 'POST';

const API_URL = config.api_url;

async function getToken() {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

function buildUrlQuery(query) {
  let qry_string = '?'
  if (query.length === 0) {
      return ''
  }
  Object.keys(query).map( key => {
      let qryValue = query[key]
      if (typeof qryValue === 'string' || typeof qryValue === 'number') {
          qry_string += `${key}=${qryValue}&`
      } else {
          qryValue.forEach((item) => {
              qry_string += `${key}=${item}&`
          })
      }
      return qry_string
  });
  return qry_string.slice(0, -1) //remove the last ampersand 
}

async function doFetch({method, URI, body, query}) {
  const token = await getToken();
  let headers = {
    Authorization: token
  }

  const options = {
    method,
    headers
  }

  if (body && Object.keys(body).length !== 0) {
    options.body = JSON.stringify(body);
  }

  let url = `${API_URL}/${URI}`;

  if (query) {
    url += buildUrlQuery(query);
  }

  const response = await fetch(url, options);
  const res = response.json()

  if (response.status >= 400) {
    const message = `${response.status} ${response.statusText}: ${res.message}`
    throw new Error(message);
  }

  return res;
}

async function getTestTemplates(pageSize = 20) {
  const response = await doFetch({
    method: HTTP_GET,
    URI: "available-tests",
    query: { namespace: "INTERNAL", page_size: pageSize },
  });

  if (!response) {
    return [];
  }

  return response;
}

async function getTestRuns(tenant, pageSize = 25, sortOrder = 'DESC') {
  const uri = `test-runs/${tenant}?page_size=${pageSize}&sort_order=${sortOrder}`
  const response = await doFetch({method: HTTP_GET, URI: uri})
  
  if (!response) {
    return[];
  }

  return response;
}

async function createTestRun(testTemplates, tenant, reason = "ui-test") {
  const requestBody = { reason };
  requestBody.tests = testTemplates.map((testTemplate) => ({
    test_id: testTemplate.id,
    namespace: "INTERNAL",
    force_unique: testTemplate.force_unique,
  }));

  const response = await doFetch({
    method: HTTP_POST,
    URI: `test-runs/${tenant}`,
    body: requestBody,
  });

  return response;
}

async function getSpecificTestRun(tenant, testRunId) {
  const response = await doFetch({method: HTTP_GET, URI: `test-runs/${tenant}/${testRunId}`})

  return response;
}

export {
  getTestTemplates,
  getTestRuns,
  createTestRun,
  getSpecificTestRun
}