import { useState, useContext } from "react";
import { JsonViewer } from "@textea/json-viewer";
import { createTestRun } from "../../api/ApiClient";
import { SessionContext } from "../../store/SessionContext";

const TestTemplate = ({ testTemplates, header }) => {
  const [showCreateTestRunNotif, setShowCreateTestRunNotif] = useState({
    shouldShow: false,
  });
  const [chosenTests, setChosenTests] = useState([]);
  const [gzipInView, setGzipInView] = useState(null);
  const [gzipModalState, setGzipModalState] = useState("modal");
  const [isCreateTestLoading, setIsCreateTestLoading] = useState(false);
  const { session } = useContext(SessionContext);
  const [forceUniqueSFTicketOptionTracker, setForceUniqueSFTicket] = useState(
    testTemplates.reduce(
      (accumulator, template) => ({ ...accumulator, [template.id]: true }),
      {}
    )
  );

  const handleTestClick = (test) => {
    setChosenTests((chosen_tests) => {
      const chosen_test_ids = {};
      for (const s of chosen_tests) {
        chosen_test_ids[s.id] = true;
      }

      // This detects a "cancelling out event" meaning if the test gets added the second time,
      // this mean they are unticking the box
      if (chosen_test_ids[test.id]) {
        return chosen_tests.filter((ct) => ct.id !== test.id);
      }

      return [...chosen_tests, test];
    });
  };

  const executeCreateTestRun = async () => {
    try {
      setIsCreateTestLoading(() => true);
      for (let test of chosenTests) {
        test.force_unique = true;
        if (test.id in forceUniqueSFTicketOptionTracker) {
          test.force_unique = forceUniqueSFTicketOptionTracker[test.id];
        }
      }
      await createTestRun(chosenTests, session.tenant);
      setIsCreateTestLoading(() => false);
    } catch (e) {
      console.log(e);
      setShowCreateTestRunNotif((state) => ({
        ...state,
        shouldShow: true,
        notifType: "FAILED",
      }));
      return;
    }

    setShowCreateTestRunNotif((state) => ({
      ...state,
      shouldShow: true,
      notifType: "SUCCESS",
    }));
  };

  const renderCreateTestRunNotif = (notifType) => {
    let notifCSS = "notification ";
    let notifText = "";

    switch (notifType) {
      case "SUCCESS":
        notifCSS += "is-primary has-text-centered";
        notifText = "Succesfully sent create test run request";
        break;
      case "FAILED":
        notifCSS += "is-danger has-text-centered";
        notifText = "Something went wrong while sending create test request";
        break;
      default:
        notifCSS += "is-danger has-text-centered";
        notifText = `notification type = ${notifType} is not supported`;
    }

    return (
      <div className={notifCSS}>
        <button
          className="delete"
          onClick={() => {
            setShowCreateTestRunNotif((state) => ({
              ...state,
              shouldShow: false,
              notifType: "",
            }));
            window.location.href = "/";
          }}
        ></button>
        {notifText}
      </div>
    );
  };

  const handleGzipView = (gzip) => {
    setGzipInView(() => gzip);
    setGzipModalState(() => "modal is-active is-clipped");
  };

  return (
    <nav className="panel is-max-desktop is-flex-grow-1">
      {showCreateTestRunNotif.shouldShow ? (
        renderCreateTestRunNotif(showCreateTestRunNotif.notifType)
      ) : (
        <></>
      )}

      {gzipInView ? (
        <div className={gzipModalState}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <JsonViewer value={gzipInView} rootName={false} theme="dark" />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={() => setGzipModalState(() => "modal")}
          ></button>
        </div>
      ) : (
        <></>
      )}
      <p className="panel-heading">{header}</p>

      {isCreateTestLoading ? (
        <>
          <progress
            className="progress is-medium is-primary mt-2"
            max="100"
          ></progress>
        </>
      ) : (
        <div className="block">
          <div style={{ overflow: "auto", maxHeight: 800 }}>
            {testTemplates.map((testTemplate) => {
              return (
                <div className="box">
                  <label
                    className="panel-block checkbox"
                    key={testTemplate.id}
                    type="checkbox"
                  >
                    <div className="block">
                      <label>
                        <input
                          type="checkbox"
                          id={testTemplate.id}
                          value={testTemplate.id}
                          onClick={() => handleTestClick(testTemplate)}
                        />
                        {testTemplate.name}
                      </label>

                      <div className="is-flex is-flex-direction-column mt-3">
                        <p className="mb-1">
                          GZIP bucket destination:{" "}
                          {testTemplate.gzip_s3_destination}
                        </p>
                        <p className="mb-1">
                          Test type: {testTemplate.test_type}
                        </p>
                        <p className="mb-1">
                          Portal Ticket Arrival From Scheduled Start Threshold:{" "}
                          {testTemplate.portal_ticket_arrival_time_limit}mins
                        </p>
                        {/* section for forcing new salesforce ticket creation */}
                        <div className="block mt-1">
                          <div
                            className="control block box content is-small"
                            style={{
                              minwidth: 200,
                              maxWidth: 300,
                              minHeight: 100,
                            }}
                          >
                            <label className="radio content is-normal">
                              <input
                                className="mr-1"
                                type="radio"
                                name={
                                  testTemplate.id + "force_unique_radio_btn"
                                }
                                defaultChecked={
                                  forceUniqueSFTicketOptionTracker[
                                    testTemplate.id
                                  ]
                                }
                                onClick={() =>
                                  setForceUniqueSFTicket((state) => {
                                    state[testTemplate.id] = true;
                                    return state;
                                  })
                                }
                              />
                              New SalesForce Ticket
                            </label>
                            <p>
                              This generates a new salesforce ticket by
                              randomizing the values of keys that were used to
                              create a unique key in SalesForce
                            </p>
                          </div>
                          <div
                            className="control block box content is-small"
                            style={{
                              minwidth: 200,
                              maxWidth: 300,
                              minHeight: 100,
                            }}
                          >
                            <label className="radio content is-normal">
                              <input
                                className="mr-1"
                                type="radio"
                                name={
                                  testTemplate.id + "force_unique_radio_btn"
                                }
                                defaultChecked={
                                  !forceUniqueSFTicketOptionTracker[
                                    testTemplate.id
                                  ]
                                }
                                onClick={() =>
                                  setForceUniqueSFTicket((state) => {
                                    state[testTemplate.id] = false;
                                    return state;
                                  })
                                }
                              />
                              Trigger Bounce Rule
                            </label>
                            <p>
                              This sends the gzip event values as is and
                              triggers the bounce rule therefore not creating a
                              new SalesForce ticket
                            </p>
                          </div>
                        </div>
                        <button
                          className="button is-small is-primary"
                          style={{ width: 100 }}
                          onClick={() =>
                            handleGzipView(testTemplate.gzip_strings)
                          }
                        >
                          View GZIP
                        </button>
                      </div>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="panel-block">
            <button
              className="button is-link is-outlined is-info is-fullwidth"
              onClick={() => executeCreateTestRun()}
            >
              Run Tests
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default TestTemplate;
