import React, { useEffect, useState } from "react";

const SessionContext = React.createContext({
  session: null,
  setSession: () => {},
});

function SessionProvider({ children }) {
  const userSession = localStorage.getItem("userSession");
  const initialSession = userSession
    ? JSON.parse(userSession)
    : {
        tenant: "hackedboxpov",
        directory_stack: [{ human_readable_text: "Dashboard", nav_path: "/" }],
      };

  const [session, setSession] = useState(initialSession);

  useEffect(() => {
    localStorage.setItem("userSession", JSON.stringify(session));
  }, [session]);

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {children}
    </SessionContext.Provider>
  );
}

export { SessionContext, SessionProvider };
