import { useContext } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { SessionContext } from "../../store/SessionContext";
import { useNavigate } from "react-router-dom";

const allowedTenants = ["hackedboxpov", "hackedboxlive", "nfr"];

const Navbar = () => {
  const { session, setSession } = useContext(SessionContext);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  function signOutStrategy() {
    signOut();
    navigate("");
    setSession((state) => ({
      ...state,
      tenant: "hackedboxpov",
      directory_stack: [{ human_readable_text: "Dashboard", nav_path: "/" }],
    }));
  }

  return (
    <nav
      className="navbar is-black is-flex-direction-row is-align-self-stretch"
      role="navigation"
      aria-label="main navigation"
      style={{ fill: "#d0f31b" }}
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="https://actzero.ai">
          <img
            src="/ActZero-Logo_rev.svg"
            alt="ActZero"
            width={150}
            height={90}
          />
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start"></div>

        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">{session.tenant}</a>

            <div className="navbar-dropdown">
              {allowedTenants.map((allowedTenant) => (
                <a
                  className="navbar-item"
                  onClick={() =>
                    setSession((state) => ({ ...state, tenant: allowedTenant }))
                  }
                >
                  {allowedTenant}
                </a>
              ))}
            </div>
          </div>
          {user ? (
            <button className="button is-dark mt-1" onClick={signOutStrategy}>
              Sign out
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
