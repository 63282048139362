import Login from "./pages/Login/Login";
import Navbar from "./pages/Navigation/Navbar";

import "@aws-amplify/ui-react/styles.css";
import "./styles/index.scss";
import "./App.css";
import "./styles/index.css"
import { SessionProvider } from "./store/SessionContext";
import { Authenticator } from "@aws-amplify/ui-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TestRunDetails from "./pages/TestRunDetails";
import { getSpecificTestRun } from "./api/ApiClient";
import Breadcrumb from "./pages/Navigation/Breadcrumb";
import { RequireAuth } from "./pages/Navigation/RequireAuth";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Navbar />
          <div className="container is-fluid">
            <Login className="main-content is-flex flex-direction-row is-justify-content-space-evenly" />
          </div>
        </>
      ),
    },
    {
      path: "/test-runs/:tenant/:testRunId",
      element: (
        <RequireAuth >
          <Navbar />
          <div className="container is-fluid">
              <Breadcrumb />
              <TestRunDetails />
          </div>
        </RequireAuth>
      ),
      loader: async ({ params }) => {
        return await getSpecificTestRun(params.tenant, params.testRunId);
      },
    },
  ]);

  return (
    <Authenticator.Provider>
      <SessionProvider>
        <RouterProvider router={router}>
        </RouterProvider>
      </SessionProvider>
    </Authenticator.Provider>
  );
}

export default App;
