const config = {
    "auth": {
      "aws_cognito_region": "us-west-2",
      "aws_user_pools_id": "us-west-2_UTL2di8SN",
      "aws_user_pools_web_client_id": "3l0122u1mp8r6c145db8nvii3j",
      "aws_cognito_login_mechanisms": [
        "EMAIL"
      ],
      "aws_cognito_signup_attributes": [
        "EMAIL"
      ],
      "aws_cognito_username_attributes": [
        "EMAIL"
      ],
      "aws_cognito_mfa_configuration": "ON",
      "aws_cognito_mfa_types": [
        "SMS"
      ],
      "aws_mandatory_sign_in": "enable",
    },
    "env": "staging",
    "api_url": "https://ewo9yxbddi.execute-api.us-west-2.amazonaws.com/staging"
}

export default config;
