import { useLoaderData } from "react-router-dom";
import TestRuns from "../TestRuns";

const fieldsToMap = [
  { expected_portal_detection_bodies: "AZ Event Id" },
  { detection_name: "Detection Name" },
  { is_running: "State" },
  { status: "Status" },
  { scheduled_start: "Scheduled start" },
  { start_time: "Start Time" },
  { completion_time: "Completion Time" },
  { reason: "Comment" },
  { portal_ticket_on_time: "Investigation Raised" },
  { portal_ticket_text_passed: "Investigation Content Matched" },
  { portal_ticket_validation_error: "Investigation Validation Error" },
  { salesforce_ticket_validation_error: "Salesforce Case Validation Error" },
];

const TestRunDetails = () => {
  const testRun = useLoaderData();

  return (
    <div className="columns is-flex is-flex-direction-column">
      <div className="column">
        <div className="box">
          <h3 className="subtitle is-4 has-text-weight-bold">
            Test Run detail
          </h3>
          <div className="columns">
            <div className="column">
              <h6 className="subtitle is-6 mb-1 has-text-weight-bold">State</h6>
              {testRun.is_running ? (
                <p className="mb-2">RUNNING</p>
              ) : (
                <p className="mb-2">DONE</p>
              )}
            </div>

            <div className="column">
              <h6 className="subtitle is-6 mb-1 has-text-weight-bold">
                Start time
              </h6>
              <p className="mb-2">
                {(() => {
                  if (testRun.start_time === undefined) {
                    return ""
                  }

                  const date = new Date(testRun.start_time);
                  return date.toGMTString();
                })()}
              </p>
            </div>

            <div className="column">
              <h6 className="subtitle is-6 mb-1 has-text-weight-bold">
                Completion time
              </h6>
              <p className="mb-2">
                {(() => {
                  if (testRun.completion_time === undefined) {
                    return ""
                  }
                  
                  const date = new Date(testRun.completion_time);
                  return date.toGMTString();
                })()}
              </p>
            </div>

            {testRun.reason ? (
              <div className="column">
                <h6 className="subtitle is-6 mb-1 has-text-weight-bold">
                  Reason
                </h6>
                <p className="mb-2">{testRun.reason}</p>
              </div>
            ) : (
              <></>
            )}
            <div className="column">
              <h6 className="subtitle is-6 mb-1 has-text-weight-bold">
                Status
              </h6>
              <p className="mb-2">{testRun.status}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-flex is-flex-direction-column">
        <TestRuns
          title="Tests"
          fieldsToMap={fieldsToMap}
          enumeratedTestRuns={testRun.tests}
          isClickable={false}
        ></TestRuns>
      </div>
    </div>
  );
};

export default TestRunDetails;
