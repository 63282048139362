import { useContext, useEffect, useState } from "react";
import { getTestRuns } from "../../api/ApiClient";
import { SessionContext } from "../../store/SessionContext";
import { useNavigate } from "react-router-dom";

const defaultFieldsToMap = [
  { run_id: "Id" },
  { is_running: "State" },
  { status: "Status" },
  { start_time: "Start Time" },
  { completion_time: "Completion Time" },
  { threats: "Threats Tested"}
];

const sortDate = (isAscending, key) => {
  if (isAscending) {
    return (a, b) => {
      const dateA = new Date(a[key]).getTime();
      const dateB = new Date(b[key]).getTime();

      return dateA - dateB;
    };
  }

  return (a, b) => {
    const dateA = new Date(a[key]).getTime();
    const dateB = new Date(b[key]).getTime();

    return dateB - dateA;
  };
};

const tableRowKeysException = ["detection_name", "threats"];

const TestRuns = ({
  title = "Test Runs",
  setDirectory,
  enumeratedTestRuns,
  fieldsToMap = defaultFieldsToMap,
  isClickable = true,
}) => {
  const [testRuns, setTestRuns] = useState([]);
  const [isTestRunsLoading, setIsTestRunsLoading] = useState(true);
  const { session } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getTenantTestRuns = async () => {
      try {
        const currentTestRuns = await getTestRuns(session.tenant);
        setTestRuns(() =>
          currentTestRuns.test_runs
        );
        setIsTestRunsLoading(() => false);
      } catch (e) {
        console.log(e);
        setTestRuns([]);
      }
    };

    if (!enumeratedTestRuns) {
      getTenantTestRuns();
      return;
    }
    setTestRuns(() =>
      enumeratedTestRuns.sort(sortDate(false, "completion_time"))
    );
    setIsTestRunsLoading(() => false);
  }, [setTestRuns, session, enumeratedTestRuns]);

  const mapTestRowData = (testRun) => {
    let testStatusCSS = "has-text-dark";

    switch (testRun.status) {
      case "FAILED":
        testStatusCSS = "has-text-danger";
        break;
      case "SCHEDULED":
      case "PARTIAL_FAILURE":
        testStatusCSS = "has-text-warning";
        break;
      case "EXECUTED":
        testStatusCSS = "has-text-info";
        break;
      case "SUCCESS":
        testStatusCSS = "has-text-success";
        break;
      default:
        break;
    }

    return (
      <tr
        onClick={() => {
          if (!isClickable) {
            return;
          }

          setDirectory({
            human_readable_text: testRun.run_id,
            nav_path: `/test-runs/${session.tenant}/${testRun.run_id}`,
          });

          navigate(`/test-runs/${session.tenant}/${testRun.run_id}`, {
            replace: true,
          });
        }}
        key={testRun.run_id}
      >
        {fieldsToMap.map((field) => {
          let tableData = <td></td>;

          for (const [key] of Object.entries(field)) {
            if (
              testRun[key] === undefined &&
              !tableRowKeysException.includes(key)
            ) {
              break;
            }

            tableData = <td>{String(testRun[key])}</td>;
            switch (key) {
              case "is_running":
                if (testRun[key]) {
                  tableData = <td>running</td>;
                  break;
                }
                tableData = <td>done</td>;
                break;
              case "status":
                tableData = <td className={testStatusCSS}>{testRun[key]}</td>;
                break;
              case "expected_portal_detection_bodies":
                const portalDetectionExpectation = Object.keys(testRun[key]);
                tableData = <td>{portalDetectionExpectation.join(", ")}</td>;
                break;
              case "start_time":
              case "completion_time":
              case "scheduled_start":
                const date = new Date(testRun[key])
                tableData = <td>{date.toGMTString()}</td>
                break;

              case "detection_name": {
                const gzipEvents = Object.values(
                  testRun["expected_portal_detection_bodies"]
                ).map((gzipEvent) => gzipEvent.e2e_alert_type);
                tableData = <td>{gzipEvents.join(", ")}</td>;
                break;
              }

              case "threats": {
                const tests = testRun['tests']
                let threatsTested = []
                for (const test of tests) {
                  const alertTypes = Object.values(
                    test["expected_portal_detection_bodies"]
                  ).map((gzipEvent) => gzipEvent.e2e_alert_type);
                  threatsTested = [...threatsTested, ...alertTypes]
                }

                tableData = <td>{threatsTested.join(", ")}</td>;
                break;
              }

              default:
                break;
            }
          }
          return tableData;
        })}
      </tr>
    );
  };

  return (
    <>
      <h3 className="title is-3 has-background-light mb-0">
        {title} <>{isTestRunsLoading ? <></> : <>({testRuns.length})</>}</>
      </h3>
      {isTestRunsLoading ? (
        <progress className="progress is-medium is-primary mt-2" max="100">
          100%
        </progress>
      ) : (
        <div className="table-container">
          <table className="table is-hoverable is-fullwidth">
            <thead>
              <tr>
                {fieldsToMap.map((field) => {
                  let tableHeader = <th></th>;
                  for (const [, value] of Object.entries(field)) {
                    tableHeader = <th>{value}</th>;
                  }
                  return tableHeader;
                })}
              </tr>
            </thead>
            <tbody>{testRuns.map((testRun) => mapTestRowData(testRun))}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TestRuns;
