import { useContext, useEffect, useState } from "react";
import TestTemplate from "../TestTemplates/TestTemplate";
import { getTestTemplates } from "../../api/ApiClient";
import TestRuns from "../TestRuns";
import { SessionContext } from "../../store/SessionContext";
import Breadcrumb from "../Navigation/Breadcrumb";

const Dashboard = (props) => {
  const { user } = props;
  const [testTemplates, setTestTemplates] = useState([]);
  const [createTest, setCreateTest] = useState(false);
  const { setSession } = useContext(SessionContext);

  useEffect(() => {
    const getTemplates = async () => {
      const templates = await getTestTemplates(100);

      setTestTemplates((state) =>
        templates.map((template) => ({
          ...template,
        }))
      );
    };

    getTemplates();
  }, [setTestTemplates, user]);

  const setDirectory = (directory) => {
    setSession((state) => ({
      ...state,
      directory_stack: [...state.directory_stack, directory],
    }));
  };

  return (
    <div className="is-flex is-flex-direction-column">
      <Breadcrumb />
      <TestRuns setDirectory={setDirectory} />
      {createTest ? (
        <div className="container">
          <div className="is-flex is-flex-direction-row is-justify-content-flex-end">
            <button
              className="delete"
              onClick={() => setCreateTest(false)}
            ></button>
          </div>

          <TestTemplate
            testTemplates={testTemplates}
            header="Available Templates"
          />
        </div>
      ) : (
        <button
          className="button is-primary"
          onClick={() => setCreateTest(true)}
        >
          Create Test
        </button>
      )}
    </div>
  );
};

export default Dashboard;
