import { Authenticator, useTheme, Heading, Text } from "@aws-amplify/ui-react";
import Dashboard from "../Dashboard/Dashboard";

const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const components = {
  VerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },

  ConfirmVerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const Login = () => {
  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
      className="is-flex is-justify-content-space-evenly is-flex-direction-row mt-6"
    >
      {({ signOut, user }) => (
        <Dashboard signOutStrategy={signOut} user={user} />
      )}
    </Authenticator>
  );
};

export default Login;
